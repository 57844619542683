import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { LottieAnimation } from "../lottie/LottieAnimation.js";
import {
  sendMessage,
  addListener,
  removeListener,
} from "../services/websocketManager";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Swal from 'sweetalert2'
import MovingText from "react-moving-text";
import Stack from "@mui/material/Stack";
import ChessButton from "./ChessButton";
import ReplayIcon from '@mui/icons-material/Replay';
import HomeIcon from '@mui/icons-material/Home';
import FilterNoneIcon from '@mui/icons-material/FilterNone';

const GameEndInfo = ({
  gameInfoFromServer,
  onRematch,
  onPlaySameGame,
  onExitToMainMenu,
  onSaveGame,
  onShareGameHistory,
  onShareBoardScreenshot,
  onClose,
  isViewer,
}) => {
  const { t } = useTranslation();
  
  const [imagePath, setImagePath] = useState(null);
  const [gameInfo, setGameInfo] = useState(null);
  const [generalStatus, setGeneralStatus] = useState(null);
  const [color, setColor] = useState("black");
  const [coins, setCoins] = useState(
    "https://res.cloudinary.com/dnlgtek2a/raw/upload/v1743418251/Lottie/coinsMinus.json"
  );
  const [rematchRequested, setRematchRequested] = useState(false);
  const [rematchDialogOpen, setRematchDialogOpen] = useState(false);
  const [responseDialogOpen, setResponseDialogOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const timeoutRef = useRef(null); // Ref for timeout
  const isMobile = window.innerWidth <= 600; // Check if the screen size is mobile
  const mobileFontSize = "10px";
  const regularFontSize = "14px";
  const startTimer = () => {
    timeoutRef.current = setTimeout(() => {
      setResponseMessage(t("No response received. Rematch was rejected"));
      //setResponseDialogOpen(true); // Show timeout response in a dialog
      
      Swal.fire({
        title: t("Error"),
        text: t("No response received. Rematch was rejected"),
        icon: 'error',
        confirmButtonText: t("OK"),
      })
      setRematchRequested(false);
    }, 10000); // 10 seconds
  };

  // Effect to handle rematch logic
  useEffect(() => {
    const handleWebSocketEvent = (event) => {
      if (event.type === "message") {
        const message = event.message;

        if (message.messageType === "rematch_request") {
          // Show dialog to accept/reject rematch
          setRematchDialogOpen(true);
          clearTimeout(timeoutRef.current); // Clear any existing timer for a new request
        } else if (message.messageType === "rematch_accept") {
          clearTimeout(timeoutRef.current); // Clear timeout
          
          Swal.fire({
            text: t("Rematch Accepted! Starting a new game..."),
            icon: 'success',
            draggable: true,
            confirmButtonText: t("OK"),
          })

          setRematchDialogOpen(false);
          onRematch(); // Trigger the rematch callback
        } else if (message.messageType === "rematch_reject") {
          clearTimeout(timeoutRef.current); // Clear timeout
          
           Swal.fire({
                    text: t("Rematch Rejected!"),
                    icon: 'error',
		                draggable: true,
                    confirmButtonText: t("OK"),
          })
          setRematchRequested(false);
        }
      }
    };

    addListener(handleWebSocketEvent);

    // If the user leaves the page, send a "rematch_declined" message
    // const handleBeforeUnload = () => {
    //   if (rematchRequested) {
    //     sendMessage({
    //       messageType: "rematch_declined",
    //       user: gameInfoFromServer?.id,
    //     });
    //   }
    // };

    // window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      removeListener(handleWebSocketEvent);
      clearTimeout(timeoutRef.current);
    };
  }, [rematchRequested, gameInfoFromServer, t, onRematch]);

  const handleRematchClick = () => {
    setRematchRequested(true);

    sendMessage({
      messageType: "rematch_request",
      gameInfo: {
        gameId: gameInfoFromServer.gameId,
      },
    });

    startTimer(); // Start the 30-second timer
  };

  const handleAcceptRematch = () => {
    sendMessage({
      messageType: "rematch_accept",
      gameInfo: {
        gameId: gameInfoFromServer.gameId,
      },
    });
    setRematchDialogOpen(false);
  };

  const handleRejectRematch = () => {
    sendMessage({
      messageType: "rematch_reject",
      gameInfo: {
        gameId: gameInfoFromServer.gameId,
      },
    });
    setRematchDialogOpen(false);
  };

  // Effect to update imagePath based on gameEndCode
  useEffect(() => {
    if (gameInfoFromServer != null) {
      setGameInfo(gameInfoFromServer);
      if (gameInfoFromServer.gameGeneralStatus === "win") {
        setGeneralStatus(t("Win"));
        setCoins("https://res.cloudinary.com/dnlgtek2a/raw/upload/v1743418251/Lottie/coinsPlus.json");
        setColor("green");
      } else if (gameInfoFromServer.gameGeneralStatus === "defeat") {
        setGeneralStatus(t("Defeat"));
        setColor("red");
        setCoins("https://res.cloudinary.com/dnlgtek2a/raw/upload/v1743418251/Lottie/coinsMinus.json");
      } else if (gameInfoFromServer.gameGeneralStatus === "draw") {
        setGeneralStatus(t("Draw"));
        setImagePath("https://res.cloudinary.com/dnlgtek2a/image/upload/v1743426965/gameEnd/draw.webp");
        setColor("blue");
      }

      if (gameInfo?.pointsWinLoseDraw < 0) {
        setColor("red");
        setCoins("https://res.cloudinary.com/dnlgtek2a/raw/upload/v1743418251/Lottie/coinsMinus.json");
      }

      if (gameInfoFromServer.gameStatus === "white_won_by_mat_black") {
        setImagePath(
          "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743422158/gameEnd/white_won.webp"
        );
      } else if (
        gameInfoFromServer.gameStatus === "white_won_by_timeout_black"
      ) {
        setImagePath(
          "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743422211/gameEnd/white_won_timeout.jpg"
        );
      } else if (gameInfoFromServer.gameStatus === "black_won_by_mat_white") {
        setImagePath(
          "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743422258/gameEnd/black_won.webp"
        );
      } else if (
        gameInfoFromServer.gameStatus === "black_won_by_timeout_white"
      ) {
        setImagePath(
          "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743422333/gameEnd/black_won_by_timeout_white.jpg"
        );
      } else if (
        gameInfoFromServer.gameStatus === "white_won_by_surrendered_black"
      ) {
        setImagePath(
          "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743422399/gameEnd/white_won_surrender_black.webp"
        );
      } else if (
        gameInfoFromServer.gameStatus === "black_won_by_surrendered_white"
      ) {
        setImagePath(
          "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743426289/gameEnd/black_won_by_surrendered_white.webp"
        );
      }
    }
  }, [gameInfoFromServer, t, gameInfo?.pointsWinLoseDraw]);

  return (
    <>
      <Card
        sx={{
          minWidth: isMobile === false ? 480 : 350,
          maxWidth: isMobile === false ? 480 : 350,
          minHeight: isMobile === false ? 560 : 400,
          maxHeight: isMobile === false ? 600 : 400,

          border: isMobile === false ? "10px solid #ccc" : "5px solid #ccc", // Add border around the Stack
          borderRadius: isMobile === false ? "15px" : "15px", // Rounded corners
          boxShadow:
            isMobile === false ? "0 25px 25px black" : "0 25px 25px black",

          overflow: "visible",
          position: "relative",
          cursor: "pointer",
          backgroundColor: "#f6ead4", // Semi-transparent background
          backdropFilter: "blur(10px)", // Frosted glass effect
        }}
      >
        
        {/* Image Section */}
        <CardMedia
          sx={{
            marginTop: "5px",
            marginLeft: "5px",
            marginRight: "5px",
            borderRadius: "20px", // Adds rounded corners
            height: isMobile === false ? 250 : 120, // Set height of the image
            objectFit: "contain",
            backgroundColor: "#f6ead4", // Add background color for better contrast
          }}
          image={imagePath}
        />

        {/* Content Section */}
        <CardContent>
          <Stack>

         

            {/* General Status */}
            <Box
              sx={{
                color: "text.primary",
                padding: 1,

                border: "4px solid #ccc",
                boxShadow: "0 12px 10px black",
                borderRadius: "15px", // Rounded corners
                alignItems: "center",
                justifyContent: "space-between",
                textAlign: "center", // Center the name under the image
              }}
            >
              <MovingText
                iteration={1}
                type="typewriter"
                dataText={[generalStatus]}
              />
            </Box>

            {/* Statistics Section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row", // Arrange boxes horizontally
                justifyContent: "space-around", // Evenly space items
                alignItems: "center", // Align items vertically
                width: "100%",
                gap: 1, // Spacing between items
                marginTop: "25px",
              }}
            >
              {/* Win/Lose/Draw */}
              {!isViewer && (
                <Box
                  sx={{
                    textAlign: "center",
                    border: "4px solid #ccc",
                    borderRadius: "15px",
                    boxShadow: "0 12px 10px black",
                    padding: 1,
                  }}
                >
                  <Box
                  component="img"
                  src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405320/Pictures/star.png"}
                  alt="Score Icon"
                  sx={{
                    width: "20px", // Adjust size if needed
                    height: "20px",
                    marginBottom: "8px",
                  }}
                />
                  <Typography
                    variant={isMobile === false ? "h6" : "h7"}
                    sx={{ color: color }}
                  >
                    {t("Earned_points") + ": "}  
                  </Typography>
                  <Typography
                    variant={isMobile === false ? "h6" : "h7"}
                    sx={{ color: color }}
                  >
                    {gameInfo?.pointsWinLoseDraw}
                  </Typography>
                </Box>
              )}

              <Divider orientation="vertical" flexItem />

              {/* Current score */}
              {!isViewer && (
                <Box 
                  sx={{
                    textAlign: "center",
                    border: "4px solid #ccc",
                    borderRadius: "15px",
                    boxShadow: "0 12px 10px black",
                    padding: 1,
                    
                  }}
                >
                   <Box
                  component="img"
                  src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405234/Pictures/coin.png"}
                  alt="Score Icon"
                  sx={{
                    width: "20px", // Adjust size if needed
                    height: "20px",
                    marginBottom: "8px",
                  }}
                />
                  <Typography
                    variant={isMobile === false ? "h6" : "h7"}
                    sx={{ color: color }}
                  >
                    {t("Earned coins") + ": "} 
                  </Typography>
                  <Typography
                    variant={isMobile === false ? "h6" : "h7"}
                    sx={{ color: color }}
                  >
                    {gameInfo?.coinsWinLoseDraw}
                  </Typography>
                </Box>
              )}
            </Box>
          </Stack>
        </CardContent>

        {/* Buttons Section */}
        <Box sx={{ padding: 0, display: "flex", flexDirection: "column" }}>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}
          >
            {localStorage.getItem("lastOpponentHumanOrAI") !== "AI" &&( <ChessButton
              onPress={handleRematchClick}
              text= {rematchRequested ? t("Rematch Requested") : t("Rematch")}
              fontSize={isMobile === true? mobileFontSize: regularFontSize}
              width={isMobile === true? "170px": "250px"}
              icon={<ReplayIcon sx={{ fontSize: 20 }} />} // pass the MUI icon here
            ></ChessButton>)}
           
            <ChessButton
              onPress={onPlaySameGame}
              text={t("Play_Same_Game")}
              fontSize={isMobile === true? mobileFontSize: regularFontSize}
              size="small"
              icon={<FilterNoneIcon sx={{ fontSize: 20 }} />} // pass the MUI icon here
            ></ChessButton>

            <ChessButton
              onPress={onExitToMainMenu}
              text={t("Exit_to_Main_Menu")}
              fontSize={isMobile === true? mobileFontSize: regularFontSize}
              size="small"
              icon={<HomeIcon sx={{ fontSize: 20 }} />} // pass the MUI icon here
            ></ChessButton>
          </Box>
        </Box>
        {gameInfo?.pointsWinLoseDraw >= 0 && (<LottieAnimation
            path={coins}
            width="2px"
            
            marginTop="1000px"
            marginLeft="50%"
            autoplay
            loop
          />)}
      </Card>
      {/* Rematch Dialog */}
      <Dialog open={rematchDialogOpen} onClose={handleRejectRematch}>
        <DialogTitle>{t("Rematch Request")}</DialogTitle>
        <DialogContent>
          <Typography>
            {t("Your opponent has requested a rematch. Do you accept?")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRejectRematch} color="error">
            {t("No")}
          </Button>
          <Button onClick={handleAcceptRematch} color="primary">
            {t("Yes")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Response Dialog */}
      <Dialog
        open={responseDialogOpen}
        onClose={() => setResponseDialogOpen(false)}
      >
        <DialogTitle>{t("Information")}</DialogTitle>
        <DialogContent>
          <Typography>{responseMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResponseDialogOpen(false)} color="primary">
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GameEndInfo;
